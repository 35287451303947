import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { TeamMember } from '../lib/Interfaces/Company';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'No.',
    type: 'number',
    width: 50,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
  },
  {
    field: 'role',
    headerName: 'Role',
    type: 'string',
    width: 100,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.role === 'USER' ? 'User' : 'Admin';
    }
  },
  {
    field: 'createdAt',
    headerName: 'Date Added',
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (params: GridValueGetterParams) => {
      const date = new Date(params.row.createdAt).toLocaleDateString()
      
      return date
    }
  }
];


export const TeamMembersTable = ({ loading, members } : {
  loading: boolean
  members: (TeamMember & {id: number})[]
}) => {

  return (
    <DataGrid
      sx={{
        color: 'white',
        width: '100%'
      }}
      pagination
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5, 10, 20]}
      rowSelection={false}
      disableColumnSelector
      className='text-white'
      rows={members}
      columns={columns}
      loading={loading}
      // rowsPerPageOptions={[5, 10, 20]}
      hideFooterSelectedRowCount
    />
  )
}