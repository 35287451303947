
import { formFieldTypes } from "./constants"
import { parse, format } from 'date-fns'
import { enUS } from 'date-fns/locale'

export function validateInput(value: string, fieldName: string, type : string){
        if(value == ""){
          return `${fieldName} cannot be empty`
        } else if( type == formFieldTypes.email){
          let isValid = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? false : true
          if (!isValid) {
            return "Not a valid Email address"
          }
        }
}

export function getHumanReadableDateFromDigiPortDataSource(input?: string): string {
  if (!input) {
    return '-'
  }

  if (input === '') {
    return 'Not Available'
  }

  const dateObject = parse(input, "MMM dd yyyy hh:mma", new Date());
  const formattedDate = format(dateObject, "(EEE) dd MMM yyyy HH:mm", { locale: enUS });

  // format the front portion of (ddd) to (DAY) capitalised
  return formattedDate.replace(/\((\w{3})\)/, (match, p1) => `(${p1.toUpperCase()})`) + ' (SGT)'
}