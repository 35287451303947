import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { UserWithUsageEntitlement } from '../lib/Interfaces/User'
import { BaseState, createBaseStore } from './baseStore'

export type SimplifiedUserState = Pick<UserWithUsageEntitlement, 'id' | 'email' | 'role' | 'concurrentSearchLimit'>

interface UserState extends BaseState {
  user: SimplifiedUserState
  company: {
    name: string
    id: string
  }
  isAdmin: boolean
  login: (user: UserWithUsageEntitlement) => void
  purge: () => void
}

const DEFAULT_USER: SimplifiedUserState = {
  id: '',
  email: '',
  role: 'USER',
  concurrentSearchLimit: 1,
}

const useUserStore = create<UserState>()(
  persist(
    (set, get, api) => ({
      ...createBaseStore(set, get, api),
      user: DEFAULT_USER,
      isAdmin: false,
      company: {
        name: '',
        id: ''
      },
      login: (user: UserWithUsageEntitlement) => {
        set({
          user: {
            id: user.id,
            email: user.email,
            role: user.role,
            concurrentSearchLimit: user.concurrentSearchLimit
          },
          company: {
            name: user.companyName,
            id: user.companyId
          },
          isAdmin: user.role === 'ADMIN',
        })
      },
      purge: () => set({
        user: DEFAULT_USER,
        isAdmin: false,
        company: {
          name: '',
          id: ''
        },
      })
    }),
    {
      name: 'user-store',
    }
  )
)

export default useUserStore