import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { request } from '../api/API';
import useUserStore from '../stores/userStore';
import { muiDarkTheme } from '../styles/global';

const PasswordResetForm = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const userId = useUserStore.getState().user.id

  const handleResetForm = () => {
    setCurrentPassword('')
    setNewPassword('')
  }

  const fetchNonce = async () => {
    console.info('Fetching nonce for user', userId)
    const nonce = await request.get('/api/nonce')
      .then((resp) => {
        return resp.data.data.nonce
      })
      .catch((error) => {
        console.error('Failed to fetch nonce', error)
        return null
      })

    return nonce
  }

  const updatePassword = async ({ nonce, currentPassword, newPassword }: {
    nonce: string,
    currentPassword: string,
    newPassword: string
  }) => {
    console.info('Updating password for user...', userId)

    const result = await request.post('/api/update-password', {
        nonce,
        current_password: currentPassword,
        new_password: newPassword
      })
      .then((r) => {
        return r.data.data.success
      })
      .catch((error) => {
        console.error('Failed to update password', error)
        return false
      })
    
    return result
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsLoading(true)
    const nonce = await fetchNonce()

    if (!nonce) {
      enqueueSnackbar('Please try again', { variant: "error" })
      return
    }

    const success = await updatePassword({
      nonce, currentPassword, newPassword
    })
    if (success) {
      enqueueSnackbar('Your password has been updated', { variant: "success" })
      handleResetForm()
    } else {
      enqueueSnackbar('Please try again', { variant: "error" })
    }

    setIsLoading(false)
  }

  return (
    <Box
      component="form"
      width='100%'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        padding: 3,
        borderRadius: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <FormControl
          sx={{
            // mx: 1,
            width: '100%'
          }}
          variant="outlined"
          required
        >
          <InputLabel htmlFor="currentPassword">
            Current Password
          </InputLabel>
          <OutlinedInput
            id="currentPassword"
            label="Current Password"
            value={currentPassword}
            type={showCurrentPassword ? 'text' : 'password'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentPassword(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowCurrentPassword((show) => !show)}
                  edge="end"
                >
                  {
                    showCurrentPassword ?
                      <VisibilityOff /> :
                      <Visibility />
                  }
                </IconButton>
              </InputAdornment>
            }
          />
          </FormControl>
        </Grid>
        <Grid item sm={12} md={6}>
          <FormControl
            sx={{
              // m: 1,
              width: '100%'
            }}
            variant="outlined"
            required
          >
            <InputLabel htmlFor="newPassword">
              New Password
            </InputLabel>
            <OutlinedInput
              id="newPassword"
              label="New Password"
              value={newPassword}
              type={showNewPassword ? 'text' : 'password'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword((show) => !show)}
                    edge="end"
                  >
                    {
                      showNewPassword ?
                        <VisibilityOff /> :
                        <Visibility />
                    }
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <Box
            sx={{
              mt: '1rem',
              mb: 'none',
              display: 'flex',
              justifyContent: {
                xs: 'flex-start',
                sm: 'center'
              }
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                handleSubmit(e)
              }}
              disabled={!currentPassword || !newPassword || isLoading}
              >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordResetForm;