import { useCallback, useContext, useEffect, useState } from "react"
import { formatRelative } from "date-fns";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Button from '@mui/material/Button';

import { getDigiPortVesselData } from "../api/API"
import { DigiPortPilotageDataContext } from "../contexts/DigiPortPilotageDataContext"
import { getHumanReadableDateFromDigiPortDataSource } from "../lib/helper"
import { DigiPortPilotageDataEntry } from "../lib/Interfaces/DigiPortPilotageData"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import useSocketStore from "../stores/socketStore";

const VesselInfoText: React.FC<{
    children?: React.ReactNode;
    className?: string;
    title: string;
  }> = ({ children, className, title }) => {
    return (
      <p
        className={className}
        style={{
          margin: 0,
        }}
      >
        <span className="font-bold">{title}:</span> {children}
      </p>
    );
  };

const DigiPortPilotageDataComponent = ({
  vesselName, digiportPilotageData
}: {
  vesselName: string,
  digiportPilotageData: DigiPortPilotageDataEntry
}) => {
  const {
    voyageInformation: {
      eta,
      etd,
      declaredArrival,
      declaredDeparture,
      reportedArrival,
      reportedDeparture,
      agent,
      lastPort,
      nextPort,
      position,
      status,
      purposes,
    },
    vesselInformation: {
      name,
      exName,
      type,
      callsign,
      flag,
      grt,
      beam,
      lengthOverall,
    },
    pilotageInformation,
  } = digiportPilotageData.data;
  

    return (
      <>
        <div className="flex flex-row">
          <div className="flex-1">
            <VesselInfoText title="Vessel Name">
              {name.toUpperCase() ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Agent Name">
              {agent ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Last Port">
              {lastPort ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Position">
              {position ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="ETA">
              {getHumanReadableDateFromDigiPortDataSource(eta)}
            </VesselInfoText>
            <VesselInfoText title="Declared Arrival">
              {getHumanReadableDateFromDigiPortDataSource(declaredArrival)}
            </VesselInfoText>
            <VesselInfoText title="Reported Arrival">
              {getHumanReadableDateFromDigiPortDataSource(reportedArrival)}
            </VesselInfoText>
          </div>
          <div className="flex-1">
            <VesselInfoText title="Call Sign">
              {callsign ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Flag">
              {flag ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Next Port">
              {nextPort ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Status">
              {status ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="ETD">
              {etd ?? '-'}
            </VesselInfoText>
            <VesselInfoText title="Declared Departure">
              {getHumanReadableDateFromDigiPortDataSource(declaredDeparture)}
            </VesselInfoText>
            <VesselInfoText title="Reported Departure">
              {getHumanReadableDateFromDigiPortDataSource(reportedDeparture)}
            </VesselInfoText>
            {/* <VesselInfoText title="Purposes">
              {purposes.join(',') ?? '-'}
            </VesselInfoText> */}
          </div>
        </div>
        <p />
        <p
          style={{
              marginTop: "0.5rem",
              marginBottom: "0.25rem",
          }}
          className="font-bold text-center"
        >
          Movement Status:
        </p>
        <table
          key={vesselName}
          style={{
            border: "1px solid black",
            margin: "0 auto",
            borderCollapse: "collapse",
          }}
        >
          {pilotageInformation.length > 0 ? 
          <thead>
            <tr>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                Timestamp
              </th>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                From
              </th>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                To
              </th>
              <th
                style={{
                  padding: "8px",
                  border: "1px solid black"
                }}
              >
                Remarks
              </th>
            </tr>
          </thead>
          : <div>
            No Pilotage Movement
          </div>
          }
          {
            pilotageInformation.map((row, idx) => {
              return (
                <>
                  <tbody>
                    <tr
                      key={idx.toString()}
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {getHumanReadableDateFromDigiPortDataSource(row.serviceRequestTime)}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {row.from}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {row.to}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid black",
                        }}
                      >
                        {row.remarks}
                      </td>
                    </tr>
                  </tbody>
                </>
              )
            })
          }
        </table>
    </>
  )
}

interface DigiPortPilotageDataProps {
  vesselName: string,
  callsign: string
}

export const DigiPortPilotageDataContainer = ({
  vesselName,
  callsign
}: DigiPortPilotageDataProps) => {
  const [isCostModalAlertOpen, setIsCostModalAlertOpen] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [dataToRender, setDataToRender] = useState<DigiPortPilotageDataEntry>();
  const { socket } = useSocketStore()

  const { data, fetchData } = useContext(DigiPortPilotageDataContext)
  // const { socketId } = useContext(DigiPortPilotageDataContext)
  
  const fetchDataFromServer = useCallback(async() => {
    if (!socket.id) {
      console.error('socketId not available in context')
      return
    }

    console.log('DigiPortPilotageDataContainer vesselName', vesselName, 'socketId=', socket.id)

    setIsFetching(true)
    await getDigiPortVesselData({
      vesselName,
      callsign,
      socketId: socket.id,
    })
  }, [socket, vesselName])

  useEffect(() => {
      const fetchedData = fetchData(vesselName)

      if (fetchedData) {
        setDataToRender(fetchedData)
      }
      setIsFetching(false)
      // need data from Context as a dependency
      // i want to check for most updated data to be rendered
  }, [data, fetchData, vesselName])
  
  return (
    <>
      <p
        style={{
            marginTop: "0.5rem",
            marginBottom: "0.25rem",
        }}
        className="font-bold text-center underline"
      >
        Vessel Status In Port (VSIP)
      </p>
      <div className="flex justify-center items-center">
        <span>
          <span className="font-bold mr-1">Last fetched</span>
          {
            dataToRender?.timestamp ?
              (<>{formatRelative(new Date(dataToRender?.timestamp), new Date())}</>) : 
              (<>-</>) 
          }
        </span>
        <span>
          <IconButton
            sx={{
              color: 'black',
              opacity: '80%'
            }}
            size="small"
            disabled={isFetching}
            aria-label={isFetching ? 'loading' : 'refresh'}
            onClick={() => {
              setIsCostModalAlertOpen(!isCostModalAlertOpen)
            }}
          >
            {
              isFetching ?
                (
                  <HourglassBottomIcon
                    sx={{
                      color: 'black',
                      opacity: '80%'
                    }}
                    fontSize="small"
                  />
                ) :
                (
                  <RefreshIcon
                    fontSize="small"
                    sx={{
                      color: 'black',
                      opacity: '80%'
                    }}
                  />
                )
            }
          </IconButton>
          <Dialog
            open={isCostModalAlertOpen}
            onClose={() => {
              setIsCostModalAlertOpen(false)
            }}
            aria-labelledby="cost-dialog-title"
            aria-describedby="cost-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to fetch pilotage data?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Each request costs S$0.33 and takes approximately 30 seconds to receive.
                For more information, please contact your person in-charge.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsCostModalAlertOpen(false)
                  fetchDataFromServer()
                }}
                autoFocus
              >
                Agree
              </Button>
              <Button
                onClick={() => {
                  setIsCostModalAlertOpen(false)
                }}
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      </div>
      <p />
      {
        dataToRender && (
          <DigiPortPilotageDataComponent
            digiportPilotageData={dataToRender}
            vesselName={vesselName}
          />
        )
      }
    </>
  )
}