import { SnackbarProvider } from "notistack"
import { PropsWithChildren } from "react"
import SnackbarCloseButton from "../../components/SnackbarCloseButton"
import { BrowserRouter } from "react-router-dom"
import AuthProvider from "./AuthProvider"
import { Router } from "./RouterProvider"
import { SECOND_IN_MS } from "../constants"
import AppProvider from "./AppProvider"
import { DigiPortPilotageDataContextProvider } from "../../contexts/DigiPortPilotageDataContext"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { muiDarkTheme } from "../../styles/global"

export const Providers = (props : PropsWithChildren) => {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiDarkTheme}>
        <BrowserRouter>
          <SnackbarProvider action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
              )}
              maxSnack={10}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              autoHideDuration={SECOND_IN_MS * 5}
            >
            <AppProvider>
              <AuthProvider>
                <DigiPortPilotageDataContextProvider>
                  <Router />
                </DigiPortPilotageDataContextProvider>
              </AuthProvider>
            </AppProvider>
          </SnackbarProvider>
        </BrowserRouter>
        </ThemeProvider>
    </StyledEngineProvider>
  )
}