import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FormController from "../components/FormController";
import Popup from "../components/Popup";
import { popupAtom } from "../jotai/store";
import {
  AlertType,
  AppRoutes,
  formFieldTypes,
  supportEmail
} from "../lib/constants";
import useAuthStore from "../stores/authStore";
import useSocketStore from "../stores/socketStore";
import {
  Image,
  Section,
  sharedButtonStyle,
  sharedFlexSpaceBetween,
} from "../styles/global";
const vesselNowLogo = require('../assets/vessel_now_logo.png')

const { input, password, email, submit } = formFieldTypes

interface LoginProps {
  email: string
  password: string
}

function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const alertMessage = useRef("")
  const { login, isAuthenticated } = useAuthStore()
  const { socket, disconnect } = useSocketStore()
  
  const resetPopup = useResetAtom(popupAtom);
  const [popupData, setPopupData] = useAtom(popupAtom);

  const handleLogin = useCallback(async (data: LoginProps) => {
    const response: string | void = await login(data.email, data.password)

    if (typeof response === 'string') {
      alertMessage.current = response

      setPopupData({
        isOpen: true,
        message: alertMessage.current,
        type: AlertType.Error,
        btnHandler: resetPopup,
      })
    } else {
      navigate(AppRoutes.VesselQuery)
    }
  }, [login, navigate, resetPopup, setPopupData])

  useEffect(() => {
    if (location.state?.from) {
      enqueueSnackbar("Please login to continue", { variant: 'warning' })
    }
  }, [location.state?.from])

  useEffect(() => {
    if (isAuthenticated) {
      // enqueueSnackbar("Logging you in...", {
      //   variant: 'success',
      //   autoHideDuration: SECOND_IN_MS * 1
      // })
      navigate(AppRoutes.VesselQuery)
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (!isAuthenticated && socket.connected) {
      disconnect()
    }
  }, [socket, disconnect, isAuthenticated])

  //Form fields for the login form
  const formFields = {
    fields: [
      {
        name: "email",
        label: "Email",
        placeholder: "Email",
        type: input,
        inputType: email,
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Password",
        inputType: password,
        type: password,
      },
    ],
    buttons: [
      {
        name: "Log In",
        type: submit,
        onSubmitHandler: (data: LoginProps) => handleLogin(data),
        style: `${sharedButtonStyle}
        width: 100%;
        background-color: #088f72;
        color: #ffffff;`,
      },
    ],
  };


  return (
    <Section className="relative">
      <LogoContainer>
        <Image className="my-4 mb-8" src={vesselNowLogo} />
      </LogoContainer>
      <FormContainer className="px-10 rounded-lg">
        <FormTitle className="text-2xl font-bold text-white">
          Login Here
        </FormTitle>
        <FormController formFields={formFields} />
      </FormContainer>
      <h1 className="text-sm mt-12 text-white">
        Experiencing issues? Contact: <a className="underline hover:text-blue-400" href={`mailto:${supportEmail}?subject=Support%20required%20for%20Vessel%20Now`}>{supportEmail}</a>
      </h1>
      {popupData.isOpen && <Popup />}
    </Section>
  );
}

export default Login;

export const LogoContainer = styled.div`
  width: 12rem;
  padding: 0rem 1rem;
`;

const FormContainer = styled.div`
  ${sharedFlexSpaceBetween}
  flex-direction: column;
  width: 30rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
`;
export const FormTitle = styled.h1`
  padding: 0.5rem 2rem;
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
`;


