import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { request } from '../api/API';
import { Role } from '../lib/Interfaces/User';
import useUserStore from '../stores/userStore';
import PasswordResetForm from './ResetPasswordForm';

export const AdminAddMemberForm = ({ fetchMembers } : { fetchMembers: () => Promise<void>}) => {
  const [email, SetEmail] = useState('');
  const [role, setRole] = useState<Role>('USER');
  const [isLoading, setIsLoading] = useState(false)
  const { company: { id } } = useUserStore()

  const handleResetForm = () => {
    SetEmail('')
    setRole('USER')
  }

  const createUser = async ({ email, role, companyId }: {
    email: string,
    role: string,
    companyId: string
  }) => {
    setIsLoading(true)
    console.info('Adding user', { email, role, companyId })

    await request.post('/api/company/user', {
        email,
        role,
        companyId,
      })
      .then((r) => {
        const isSuccess = r.status === 200
        const message = `User has been added${!isSuccess && '. Please contact Support to retrieve credentials'}.`

        enqueueSnackbar(message, { variant: isSuccess ? "success" : "info" })
        handleResetForm()
        fetchMembers()
        // return r.data.data.success
      })
      .catch((error) => {
        console.error('Failed to add team member. Please try again.', error)
        enqueueSnackbar(`Failed to add user: ${error.response}`, { variant: "error" })
        // return false
      })

    setIsLoading(false)
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await createUser({
      email, role, companyId: id
    })
  }

  return (
      <Box
        component="form"
        width='100%'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          padding: 3,
          borderRadius: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <FormControl
            sx={{
              // mx: 1,
              width: '100%'
            }}
            variant="outlined"
            required
          >
            <InputLabel htmlFor="currentPassword">
              User Email
            </InputLabel>
            <OutlinedInput
              id="email"
              label="Email"
              value={email}
              type='text'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                SetEmail(event.target.value);
              }}
            />
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
            <InputLabel id="role">Role</InputLabel>
            <Select
              labelId="role"
              id="role"
              value={role}
              label="Role"
              onChange={(event: SelectChangeEvent<string>) => {
                setRole(event.target.value as Role);
              }}
            >
              <MenuItem value='USER'>User</MenuItem>
              <MenuItem value='ADMIN'>Admin</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item sm={12}>
            <Box
              sx={{
                width: '100%',
                mt: '1rem',
                mb: 'none',
                display: 'flex',
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'center'
                }
              }}
            >
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleSubmit(e)
                }}
                disabled={!email || isLoading}
                >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
  );
};

export default PasswordResetForm;