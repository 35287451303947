import React from 'react';
import VesselMapObj from '../lib/Interfaces/VesselMapObj';
import { arrowColors } from '../lib/constants';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface VesselAlertDisplayCardProps {
    vessel: VesselMapObj;
}

const VesselAlertDisplayCard: React.FC<VesselAlertDisplayCardProps> = ({ vessel}) => {
    const color = arrowColors[vessel.colorIndex]


    return <>
        <div className={`relative my-2 w-full flex flex-row place-content-between overflow-x-hidden border border-solid border-black`}  >
            <div className='absolute w-2 left-0 h-full' style={{ backgroundColor: `rgb(${color.red},${color.green},${color.blue})` }}>
            </div>
            <div className={`py-2 px-3 w-max flex-grow`}>

                {vessel.name !== "" ? <>
                    <span className='flex flex-row w-max my-auto'>
                        <h1 className='font-bold text-black mr-2 text-sm text-ellipsis'>{vessel.name}</h1>
                    </span>
                </> : <>
                    <Skeleton width={"60%"} />
                </>}

                <p className='text-gray-600 text-start flex-nowrap text-xs overflow-ellipsis xl:text-sm overflow-hidden'>{vessel.IMO !== "" ? `IMO ${vessel.IMO}` : ""}</p>
            </div>
        </div>

    </>
}

export default VesselAlertDisplayCard;