import React from "react";
import VesselInfo from "../lib/Interfaces/VesselInfo";

interface VesselResultCardV2Props {
  vessel: VesselInfo;
  handleAddToFleet: Function;
}

const VesselResultCardV2: React.FC<VesselResultCardV2Props> = ({
  vessel,
  handleAddToFleet,
}) => {
  return (
    <>
      <div className="relative my-1 w-full bg-white text-start border border-solid flex flex-row hover:cursor-pointer pr-3 max-w-md mx-auto">
        <div
          className="flex-grow py-2 px-3 w-max"
          onClick={() => {
            handleAddToFleet(vessel);
          }}
        >
          <h1 className="font-bold text-sm">
            {vessel.vesselName}{" "}
            {vessel.vesselCallsign ? `(${vessel.vesselCallsign})` : ""}
            {vessel.vesselFlag ? `- ${vessel.vesselFlag}` : ""}
          </h1>
          <p className="text-gray-600 text-sm">
            {vessel.vesselType}
            {vessel.vesselImo && vessel.vesselImo != "0"
              ? `, IMO ${vessel.vesselImo}`
              : ""}
            {vessel.vesselMMSI && vessel.vesselMMSI != "0"
              ? `, MMSI ${vessel.vesselMMSI}`
              : ""}
          </p>
        </div>
      </div>
    </>
  );
};

export default VesselResultCardV2;
