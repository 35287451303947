import axios from 'axios';
import { convertToVesselDueToArrive, convertToVesselMapObj, SERVER_URL } from '../lib/constants';
import VesselMapObj from '../lib/Interfaces/VesselMapObj';

interface SuccessResponse<T> {
    data: T
}

interface ErrorResponse {
    error: {
        status: number,
        message: string
    }
}

export type ApiResponse<T> = SuccessResponse<T> | ErrorResponse

// Type guard to check if the response is a success response
export function isSuccessResponse<T>(response: ApiResponse<T>): response is SuccessResponse<T> {
    return 'data' in response;
}

// Type guard to check if the response is an error response
export function isErrorResponse(response: ApiResponse<unknown>): response is ErrorResponse {
    return 'error' in response;
}

export const request = axios.create({
    baseURL: SERVER_URL,
    withCredentials: true
})

export async function getVesselLocation(IMOs: string[], sid: string, origin: 'imo' | 'name') {
    const data = {
        imos: IMOs,
        websocketId: sid,
        searchOrigin: 'imo'
    }
        console.log('getVesselLocation API data', data)

        
    try {
        const response = await request.post('/api/vessel-location', data);

        if (response.status !== 200) {
            return {
                status: response.status,
                vessels: []
            }
        }
        let vesselsData = []

        for (const res of response.data) {
            vesselsData.push(convertToVesselMapObj(res))
        }
        return {
            status: 200,
            vessels: vesselsData
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            vessels: []
        }
    }

}

export async function getBookmarks() {
    try {
        const response = await request.get('/api/bookmark');

        let vesselsData = []
        for (const res of response.data.vessels) {
            vesselsData.push({
                id: res.id,
                name: res.name,
                type: res.type,
                latitude: "",
                longitude: "",
                IMO: res.imo,
                MMSI: res.mmsi,
                flag: res.flag,
                visible: true,
                course: 0,
                loading: false,
                colorIndex: -1,
                pilotageInfo: null,
                currentPilotageInfo: null,
                bookmarked: true,
                eta: null,
                etd: null,
                callsign: res.callsign,
                speed: 0,
                destination: null,
                timeStamp: null,
                popupOpen: false,
                hasPilotageData: true,
                aliases: res.vessel_alias ? res.exName.split(','): []
            })
        }
        return vesselsData
    } catch (e) {
        console.log(e)
    }
}

export async function removeBookmark(id: String) {
    try {
        const response = await request.delete('/api/bookmark',
          {
            withCredentials: true,
            data: {
              vesselId: id
            }
          }
          );

        return response
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            vessels: []
        }
    }
}

export async function addBookmark(vessel: VesselMapObj) {
    try {
        const response = await request.post('/api/add-vessel-to-bookmark',
            {
                vessel: {
                    name: vessel.name,
                    mmsi: vessel.MMSI ?? "",
                    imo: vessel.IMO,
                    callsign: vessel.callsign,
                    type: vessel.type,
                    flag: vessel.flag,
                    exName: vessel.aliases
                }
            });
        return response
    } catch (e: any) {
        console.log(e)
        return {
            status: e.response.status,
            data: null
        }
    }
}

export async function getVesselNameSearchResults(search: string) {
    try {
        const vesselNameSearchResults = await request.get(`/api/search-vessel-name/${search}`)
        if (vesselNameSearchResults.status === 200) {
            return {
                status: vesselNameSearchResults.status,
                data: vesselNameSearchResults.data
            }
        } else {
            return {
                status: vesselNameSearchResults.status,
                data: []
            }
        }
    }
     catch(e) {
        return {
            status: 404,
            data: []
        }
    }
}

export async function getUserType() {
        let userRole = {
            "account_type": 3,
            "notification_frequency": "1"
        }

        return userRole
}

export async function getVesselsDueToArrive() {
    const response = await request.get('/api/due-to-arrive');

    return convertToVesselDueToArrive(response.data)
}

export async function getDigiPortVesselData({
    vesselName, callsign, socketId
}: {
    vesselName: string,
    callsign: string,
    socketId: string,
}): Promise<boolean> {
    try {
        await request.post('/api/vessel-status-in-port/send', {
            vesselName: vesselName,
            callsign: callsign,
            socketId: socketId
        })
        return true
    } catch (error) {
        console.error('Error calling POST /api/vessel-status-in-port/send', error)

        return false
    }
}