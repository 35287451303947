import { enqueueSnackbar } from "notistack"
import { PropsWithChildren, useEffect } from "react"
import useSocketStore from "../../stores/socketStore"

/**
 * App Provider that contains general app state context
 */
const AppProvider = ({ children }: PropsWithChildren) => {
  const { connect, setId, socket } = useSocketStore()

  useEffect(() => {
    const handleConnect = () => {
      setId(socket.id)
      enqueueSnackbar(`Established live connection to server: ${socket.id}`, {
        variant: 'success',
        preventDuplicate: true
      })
    }

    const handleDisconnect = () => {
      enqueueSnackbar('Disconnected live connection from server', {
        variant: 'warning',
        preventDuplicate: true
      })
    }

    socket.on('connect', handleConnect)
    socket.on('disconnect', handleDisconnect)

    return () => {
      socket.off('connect', handleConnect)
      socket.off('disconnect', handleDisconnect)
    }
  }, [connect, setId, socket])  

  return <>{children}</>
}

export default AppProvider