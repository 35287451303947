import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { closeSnackbar } from 'notistack';


interface SnackbarCloseButtonProps {
    snackbarKey: any
}


const SnackbarCloseButton: React.FC<SnackbarCloseButtonProps> = ({snackbarKey}) => {
    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
          <Close />
        </IconButton>
      );

}


export default SnackbarCloseButton