import { PropsWithChildren, useCallback, useEffect, useRef } from "react"
import useAuthStore, { refreshTokenIntervalInMs } from "../../stores/authStore"
import { isAfter } from "date-fns"

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, refreshToken, tokenExpirationTime } = useAuthStore()
  const isInitialMount = useRef(true)

  const checkAndRefresh = useCallback(async () => {
    const date = new Date()

      if (tokenExpirationTime && isAfter(date, tokenExpirationTime)) {
        console.log({
          action: 'Eligible for refreshing...',
          currentTime: date,
          tokenExpirationTime: tokenExpirationTime ? new Date(tokenExpirationTime) : 'Not set',
        })

        await refreshToken()
        console.log('Success refreshing new tokenExpirationTime', new Date(tokenExpirationTime))
      } else {
        console.log({
        action: 'Not eligible for refresh',
        currentTime: date,
        tokenExpirationTime: tokenExpirationTime ? new Date(tokenExpirationTime) : 'Not set',
      })
    }
  }, [refreshToken, tokenExpirationTime])

  useEffect(() => {
    let refreshIntervalTimer: NodeJS.Timer

    const setupRefreshCycle = () => {
      if (isInitialMount.current) {
        checkAndRefresh()
        isInitialMount.current = false
      }
      refreshIntervalTimer = setInterval(checkAndRefresh, refreshTokenIntervalInMs)
    }

    if (isAuthenticated) {
      setupRefreshCycle()
    }

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        console.info('Session active again, checking token expiry and will refresh if needed')
        checkAndRefresh()
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      if (refreshIntervalTimer) {
        clearInterval(refreshIntervalTimer)
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [isAuthenticated, checkAndRefresh])

  return <>{children}</>
}

export default AuthProvider