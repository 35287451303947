import {
  AlarmOn,
  ArrowBackIos,
  ArrowForwardIos,
  DirectionsBoat,
  Email,
  GpsFixed,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import VesselCardV2 from "./VesselCardV2";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import { Button } from "@mui/material";
import { set } from "react-hook-form";
import TableViewIcon from "@mui/icons-material/TableView";
import CancelIcon from '@mui/icons-material/Cancel';

interface MyFleetProps {
  vessels: VesselMapObj[];
  handleNavigateToVessel: Function;
  handleSelectedVessel: Function;
  selectedVessel: VesselMapObj[];
  handleRemoveVessel: Function;
  handleSelectAllVessels: Function;
  handleRetrieveVesselLocation: Function;
  handleBookmark: Function;
  handleOpenTable: Function;
  handleOpenAlertModal: Function;
  userType: number;
}

const MyFleet: React.FC<MyFleetProps> = ({
  vessels,
  userType,
  handleSelectAllVessels,
  handleNavigateToVessel,
  handleRetrieveVesselLocation,
  selectedVessel,
  handleOpenAlertModal,
  handleSelectedVessel,
  handleRemoveVessel,
  handleOpenTable,
  handleBookmark,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentVesselView, setCurrentVesselView] = useState<VesselMapObj[]>(
    []
  );

  useEffect(() => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    setCurrentVesselView(vessels.slice(startIndex, endIndex));
  }, [currentPage, vessels]);

  function handlePageChange(newPage: any) {
    setCurrentPage(newPage);
  }
  return (
    <>
      <div className="flex-grow">
        <div className="w-max mx-auto relative">
          <div className="flex flex-row items-center">
            <DirectionsBoat
              htmlColor="white"
              className="mx-1 inline-block"
              fontSize="medium"
            />
            <h1 className="text-white h-min inline-block whitespace-nowrap text-2xl xl:text-3xl">
              My Fleet ({vessels.length})
            </h1>
          </div>
        </div>
        <hr className="border-t border-white w-1/2 my-3 mx-auto" />
        <div>
          <div className="flex flex-row gap-3 justify-between mb-4">
            {vessels.length !== 0 && (
              <>
                <div className="flex-1 flex gap-1">
                  <Button
                    variant="contained"
                    size="medium"
                    className="whitespace-nowrap font-bold py-2 px-4 rounded flex-1"
                    onClick={() => handleSelectAllVessels()}
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "0.75rem",
                      "&:hover": {
                        backgroundColor: "white", // Set the hover background color to the same as the default
                        color: "black", // Set the hover text color to the same as the default
                      },
                    }}
                  >
                    Select all
                  </Button>
                  {selectedVessel.length > 0 ? (
                    <>
                      <Button
                        variant="contained"
                        size="medium"
                        className="whitespace-nowrap font-bold py-2 px-4 rounded"
                        onClick={() => handleRemoveVessel()}
                        sx={{
                          backgroundColor: "white",
                          color: "black",
                          fontSize: "0.75rem",
                          "&:hover": {
                            backgroundColor: "red", // Set the hover background color to the same as the default
                            color: "white", // Set the hover text color to the same as the default
                          },
                        }}
                      >
                        <CancelIcon/>
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <Button
                  variant="contained"
                  size="medium"
                  className="flex-grow whitespace-nowrap font-bold py-2 px-4 rounded ml-2"
                  sx={{
                    fontSize: "0.75rem",
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "black", // Set the hover background color to the same as the default
                      color: "white", // Set the hover text color to the same as the default
                    },
                  }}
                  onClick={() => handleRetrieveVesselLocation()}
                >
                  <GpsFixed
                    style={{
                      fontSize: "18px",
                    }}
                  />
                </Button>
                {/* 
                        {userType >= 3 ?
                            <Button
                                variant="contained"
                                size="medium"
                                className="flex-grow whitespace-nowrap font-bold py-2 px-4 rounded ml-2"
                                sx={{
                                    fontSize: '0.75rem',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: 'black', // Set the hover background color to the same as the default
                                        color: 'white', // Set the hover text color to the same as the default
                                    },
                                }}
                                onClick={() => handleOpenAlertModal()}
                            >
                                <AlarmOn style={{
                                    fontSize: '18px',
                                }} />
                            </Button>
                            : <></>}
                             */}
                {userType >= 2 ? (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "0.5rem",
                      "&:hover": {
                        backgroundColor: "black", // Set the hover background color to the same as the default
                        color: "white", // Set the hover text color to the same as the default
                      },
                    }}
                    onClick={() => handleOpenTable()}
                  >
                    <TableViewIcon />
                  </Button>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          {/*                 {
                    vessels.length > 0 ?
                        <>
                            <div className="flex justify-center mb-4 text-white">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <ArrowBackIos />
                                </button>
                                <span className="mx-2">{`Page ${currentPage} of ${Math.ceil(vessels.length / 5)}`}</span>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === Math.ceil(vessels.length / 5)}
                                >
                                    <ArrowForwardIos />
                                </button>
                            </div>
                        </> : <></>
                } */}
          <div
            style={{
              maxHeight: "55vh",
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              scrollbarColor: "black white",
            }}
            className="overflow-y-auto overflow-x-hidden"
          >
            {vessels.length == 0 ? (
              <p className="text-2xl text-white w-max mx-auto my-2 whitespace-nowrap">
                Fleet is empty
              </p>
            ) : (
              <>
                {currentVesselView.map((vessel) => {
                  return (
                    <VesselCardV2
                      userType={userType}
                      key={vessel.id}
                      handleRemoveVessel={(vessel: VesselMapObj) => {
                        if (vessels.length <= 6) {
                          setCurrentPage(1);
                        }
                        handleRemoveVessel(vessel);
                      }}
                      handleBookmark={handleBookmark}
                      handleSelectedVessel={handleSelectedVessel}
                      selected={
                        selectedVessel.findIndex((sv) => sv == vessel) >= 0
                      }
                      vessel={vessel}
                      handleNavigateToVessel={handleNavigateToVessel}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFleet;
