import { useEffect } from "react"
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Login from "../../pages/Login"
import { SettingsPage } from "../../pages/Settings"
import VesselLocation from "../../pages/VesselLocation"
import useAuthStore from "../../stores/authStore"
import useSocketStore from "../../stores/socketStore"
import { AppRoutes } from "../constants"

/**
 * component which manages user interactions when unauthenticated user is on protected routes
 * @returns 
 */
const ProtectedRoute = () => {
  const { isAuthenticated } = useAuthStore()
  const { disconnect, connect } = useSocketStore()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // console.log('ProtectedRouteComp Triggered, isAuthenticated', isAuthenticated)
    if (!isAuthenticated) {
      disconnect()
      navigate(AppRoutes.Login,
        {
          state: {
            from: location.pathname
          }
        }
      )
    }

    connect()
  }, [
    connect,
    disconnect,
    isAuthenticated,
    location,
    navigate
  ])

  return (
    <Outlet />
  )
}


export const Router = () => {
  return (
    <Routes>
      <Route path={AppRoutes.Login} element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path={AppRoutes.VesselQuery} element={<VesselLocation vessels={[]} />} />
        <Route path={AppRoutes.Settings} element={<SettingsPage />} />
      </Route>
    </Routes>
  )
}