import { AxiosResponse } from 'axios'
import { addMilliseconds } from "date-fns"
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { ApiResponse, isErrorResponse, request } from '../api/API'
import { MIN_IN_MS } from '../lib/constants'
import { UserWithUsageEntitlement } from '../lib/Interfaces/User'
import { BaseState, createBaseStore } from './baseStore'
import useUserStore from './userStore'

export const refreshTokenIntervalInMs = process.env.NODE_ENV === 'development' ? MIN_IN_MS * 2 : MIN_IN_MS * 14

interface AuthState extends BaseState {
  isAuthenticated: boolean
  shouldUpdatePassword: boolean
  // tokenExpirationTime is managed by global state, not to be confused with JWT's lifespan
  // automatically calculated upon successful refresh and login
  tokenExpirationTime: null | Date
  refreshToken: () => Promise<void>
  login: (email: string, password: string) => Promise<string | void>
  logout: () => void
  checkAndPurgeStores: (loggedInUserId: string) => void
}

const useAuthStore = create<AuthState>()(
  persist(
    (set, get, api) => ({
      ...createBaseStore(set, get, api),
      isAuthenticated: false,
      shouldUpdatePassword: false,
      tokenExpirationTime: null,
      login: async (email: string, password: string) => {
        try {
          const response: AxiosResponse<ApiResponse<{
              success: boolean
              user: UserWithUsageEntitlement
          }>> = await request.post('/api/login', { email, password })

          const tokenExpirationTime = addMilliseconds(new Date(), refreshTokenIntervalInMs)

          if (isErrorResponse(response.data)) {
            return response.data.error.message
          }

          get().checkAndPurgeStores(response.data.data.user.id)
          useUserStore.getState().login(response.data.data.user)
          set({
            isAuthenticated: true,
            shouldUpdatePassword: response.data.data.user.shouldUpdatePassword,
            tokenExpirationTime,
          })
        } catch (error) {
          console.error('Login failed!', error)
          return 'Failed to login, please try again'
        }
      },
      refreshToken: async () => {
        try {
          await request.get('/api/refresh')
          set({
            tokenExpirationTime: addMilliseconds(new Date(), refreshTokenIntervalInMs)
          })
        } catch (error) {
          get().logout()
        }
        
      },
      logout: () => {
        set({
          isAuthenticated: false,
          shouldUpdatePassword: false,
          tokenExpirationTime: null
        })
      },
      checkAndPurgeStores: (loggedInUserId: string) => {
        const userInPersistedStore = useUserStore.getState().user

        if (!userInPersistedStore) {
          return
        }

        if (userInPersistedStore.id !== loggedInUserId) {
          useUserStore.getState().purge()
        }
      },
    }),
    {
      name: 'auth-store',
    }
  )
)

export default useAuthStore