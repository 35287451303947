import { Box } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { request } from "../api/API";
import { GetCompanyInfoResponse, TeamMember } from "../lib/Interfaces/Company";
import { TeamMembersTable } from "./TeamMembersTable";
import { AdminAddMemberForm } from "./AdminAddTeamMemberForm";

export const AdminManageTeamComponent = () => {
  const [members, setMembers] = useState<TeamMember[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchMembers = useCallback(async() => {
    await request.get<GetCompanyInfoResponse>('/api/company')
      .then((r) => {
        if ('data' in r.data) {
          setMembers(r.data.data.users as TeamMember[])
        } else {
          throw new Error(' Failed to fetch team members')
        }
      })
      .catch((e) => {
        enqueueSnackbar('Failed to fetch team members', { variant: 'warning' })
        setMembers([])
        return []
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchMembers()
  }, [fetchMembers])

  return (
    <>
      <Box
        component="form"
        width='100%'
        className='text-lg text-white'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <TeamMembersTable members={members.map((m, idx) => {
          return {
            ...m,
            id: idx + 1
          }
        })} loading={isLoading} />
        <Box>
          <div className="text-center text-2xl font-bold text-white mb-4">
            Invite Member
          </div>
          <AdminAddMemberForm fetchMembers={fetchMembers} />
        </Box>
      </Box>
    </>
  )
}