import React from "react";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import { arrowColors } from "../lib/constants";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { CircleSpinner } from "react-spinners-kit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";

interface VesselCardV2Props {
  vessel: VesselMapObj;
  handleNavigateToVessel: Function;
  selected: boolean;
  handleSelectedVessel: Function;
  handleRemoveVessel: Function;
  handleBookmark: Function;
  userType: number;
}

const VesselCardV2: React.FC<VesselCardV2Props> = ({
  vessel,
  userType,
  handleNavigateToVessel,
  handleSelectedVessel,
  selected,
  handleRemoveVessel,
  handleBookmark,
}) => {
  const color = arrowColors[vessel.colorIndex];

  return (
    <>
      <div
        className={`relative my-1 w-full ${
          selected ? "bg-blue-300" : "bg-white"
        } hover:cursor-pointer flex flex-row place-content-between overflow-x-hidden`}
      >
        <div
          className="absolute w-2 left-0 h-full"
          style={{
            backgroundColor: `rgb(${color.red},${color.green},${color.blue})`,
          }}
        ></div>
        <div
          className={`py-2 px-3 w-max flex-grow`}
          onClick={() => {
            handleSelectedVessel(vessel);
          }}
          onDoubleClick={() => {
            if (vessel.latitude !== "" || vessel.longitude !== "") {
              handleNavigateToVessel(vessel);
            }
          }}
        >
          {vessel.name !== "" ? (
            <>
              <span className="flex flex-row w-max my-auto">
                <h1 className="font-bold text-black mr-2 text-sm text-ellipsis">
                  {vessel.name} {vessel.callsign ? `(${vessel.callsign})` : ""}{" "}
                  {vessel.flag ? `- ${vessel.flag}` : ""}
                </h1>
                {vessel.loading ? (
                  <CircleSpinner color={"grey"} size={15} />
                ) : vessel.latitude !== "" && vessel.longitude !== "" ? (
                  <GpsFixedIcon
                    style={{
                      fontSize: "18px",
                    }}
                    color="primary"
                  />
                ) : (
                  <></>
                )}
              </span>
            </>
          ) : (
            <>
              <Skeleton width={"60%"} />
            </>
          )}

          <p className="text-gray-600 flex-nowrap text-xs overflow-ellipsis xl:text-sm overflow-hidden">
            {vessel.type !== "" && vessel.type !== undefined
              ? `${vessel.type}, `
              : ""}
            {vessel.IMO !== "" ? `IMO ${vessel.IMO}` : ""}
          </p>
        </div>
        <div className="pr-3 my-auto justify-end">
          {userType >= 2 ? (
            vessel.bookmarked ? (
              <>
                <StarIcon
                  sx={{
                    fontSize: "1.5rem", // Default size
                    "@media (max-width: 1270px)": {
                      fontSize: "1rem", // Smaller size for screens <= 1024px
                    },
                  }}
                  onClick={() => {
                    handleBookmark(vessel.IMO);
                  }}
                  htmlColor="gold"
                />
              </>
            ) : (
              <>
                <StarOutlineIcon
                  sx={{
                    fontSize: "1.5rem", // Default size
                    "@media (max-width: 1270px)": {
                      fontSize: "1rem", // Smaller size for screens <= 1024px
                    },
                  }}
                  onClick={() => {
                    handleBookmark(vessel.IMO);
                  }}
                />
              </>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default VesselCardV2;
