import { Button, Input, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import { Close, Search } from "@mui/icons-material";
import { useState } from "react";
import { getCurrentDateTime } from "../lib/constants";
const { v4: uuidv4 } = require("uuid");

interface TableViewModalProps {
  vessels: VesselMapObj[];
  handleCloseTable: Function;
  userType: number;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: `vessel_pilotage_information_${getCurrentDateTime()}`,
        }}
        printOptions={{
          disableToolbarButton: true,
        }}
      />
    </GridToolbarContainer>
  );
}

const TableViewModal: React.FC<TableViewModalProps> = ({
  vessels,
  userType,
  handleCloseTable,
}) => {
  let vesselDisplay: any[] = [];
  vessels.forEach((vessel) => {
    if (vessel.pilotageInfo) {
      vessel.pilotageInfo.forEach((pi) => {
        vesselDisplay.push({
          ...vessel,
          id: uuidv4(),
          pilotageInfo: [pi],
        });
      });
    } else {
      vesselDisplay.push(vessel);
    }
  });
  const columns: GridColDef<VesselMapObj[][number]>[] = [
    {
      field: "name",
      headerName: "Vessel Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "IMO",
      headerName: "IMO",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "callsign",
      headerName: "Callsign",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "destination",
      headerName: "Destination",
      minWidth: 220,
      flex: 1,
    },
    {
      field: "eta",
      headerName: "ETA",
      minWidth: 220,
      flex: 1,
    },
    {
      field: "etd",
      headerName: "ETD",
      minWidth: 220,
      flex: 1,
    },
    {
      field: "latitude",
      headerName: "Latitude",
      type: "number",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "longitude",
      headerName: "Longitude",
      type: "number",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "course",
      headerName: "Course",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "speed",
      headerName: "Speed",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "pilotageInfo.pilotage_cst_dt_time",
      headerName: "Pilotage_cst_dt_time",
      type: "string",
      valueGetter: (params) =>
        params.row.pilotageInfo?.[0].pilotage_cst_dt_time?.toString(),
      minWidth: 220,
      flex: 1,
    },
    {
      field: "pilotageInfo.pilotage_arrival_dt_time",
      headerName: "Pilotage_arrival_dt_time",
      type: "string",
      valueGetter: (params) =>
        params.row.pilotageInfo?.[0].pilotage_arrival_dt_time?.toString(),
      minWidth: 220,
      flex: 1,
    },
    {
      field: "pilotageInfo.pilotage_onboard_dt_time",
      headerName: "Pilotage_onboard_dt_time",
      type: "string",
      valueGetter: (params) =>
        params.row.pilotageInfo?.[0].pilotage_onboard_dt_time?.toString(),
      minWidth: 220,
      flex: 1,
    },
    {
      field: "pilotageInfo.pilotage_start_dt_time",
      headerName: "Pilotage_start_dt_time",
      type: "string",
      valueGetter: (params) =>
        params.row.pilotageInfo?.[0].pilotage_start_dt_time?.toString(),
      minWidth: 220,
      flex: 1,
    },
    {
      field: "pilotageInfo.pilotage_end_dt_time",
      headerName: "Pilotage_end_dt_time",
      type: "string",
      valueGetter: (params) =>
        params.row.pilotageInfo?.[0].pilotage_end_dt_time?.toString(),
      minWidth: 220,
      flex: 1,
    },
    {
      field: "pilotageInfo.pilotage_loc_from_code",
      headerName: "Pilotage_loc_from_code",
      valueGetter: (params) =>
        params.row.pilotageInfo?.[0].pilotage_loc_from_code,
      minWidth: 220,
      flex: 1,
    },
    {
      field: "pilotageInfo.pilotage_loc_to_code",
      headerName: "Pilotage_loc_to_code",
      valueGetter: (params) =>
        params.row.pilotageInfo?.[0].pilotage_loc_to_code,
      minWidth: 220,
      flex: 1,
    },
  ];
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white aspect-auto h-full lg:h-auto lg:aspect-video w-full lg:w-9/12 z-50 rounded shadow-lg overflow-hidden"
        style={{
          maxWidth: "1800px",
          maxHeight: "1013px",
        }}
      >
        <div className="flex flex-col items-center justify-center h-full mx-auto text-center px-10 relative">
          <Close
            className="absolute hover:cursor-pointer right-2 top-2"
            onClick={() => handleCloseTable()}
          />
          <div
            style={{ height: "85%", width: "100%", overflowX: "auto" }}
            className="flex"
          >
            <DataGrid
              rows={vesselDisplay.filter((vessel) => {
                return vessel.latitude !== "" && vessel.longitude !== "";
              })}
              columns={columns}
              autoHeight={false}
              pageSizeOptions={[5, 10]}
              slots={{
                toolbar: userType >= 2 ? CustomToolbar : null,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableViewModal;
