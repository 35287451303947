import { Close } from "@mui/icons-material";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import VesselAlertDisplayCard from "./VesselAlertDisplayCard";

interface AlertModalProps {
  handleAlertModalClose: Function;
  selectedVessels: VesselMapObj[];
}

const AlertModal: React.FC<AlertModalProps> = ({
  handleAlertModalClose,
  selectedVessels,
}) => {
  const [alertType, setAlertType] = useState({
    pilotageAlert: false,
    locationAlert: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertType({
      ...alertType,
      [event.target.name]: event.target.checked,
    });
  };

  const { pilotageAlert, locationAlert } = alertType;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div
          className="bg-white aspect-auto h-full lg:h-auto lg:aspect-video w-full lg:w-6/12 z-50 rounded shadow-lg overflow-hidden"
          style={{
            maxWidth: "1800px",
            maxHeight: "1013px",
          }}
        >
          <div className="flex flex-col items-center justify-center h-full mx-auto text-center px-10 relative">
            <Close
              className="absolute hover:cursor-pointer right-2 top-2"
              onClick={() => handleAlertModalClose()}
            />
            <div
              className="flex flex-row"
              style={{ height: "85%", width: "100%" }}
            >
              <div className="flex-1 my-auto">
                {selectedVessels.map((vessel) => {
                  return <VesselAlertDisplayCard vessel={vessel} />;
                })}
              </div>
              <div className="flex flex-col flex-1 px-4">
                <div className="my-auto ">
                  <h1 className=" text-3xl font-bold">Configure Alert</h1>
                  <Box sx={{ minWidth: 400, width: "100%" }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <FormLabel component="legend">Alert Type</FormLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pilotageAlert}
                            onChange={handleChange}
                            name="pilotageAlert"
                          />
                        }
                        label="Pilotage"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={locationAlert}
                            onChange={handleChange}
                            name="locationAlert"
                          />
                        }
                        label="Location"
                      />
                    </FormControl>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertModal;
