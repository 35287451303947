import { Close, Logout } from "@mui/icons-material";
import {
  alpha,
  Button,
  InputBase,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { getVesselsDueToArrive } from "../api/API";
import VesselDueToArrive from "../lib/Interfaces/VesselDueToArrive";
import { extractDateRange } from "../lib/constants";

interface DueToArriveSideBarContentProps {
  logout: Function;
  handleLogout: Function;
  isLargeScreen: boolean;
  handleCloseSidebar: Function;
}
type SearchKey = "vesselName" | "imoNumber" | "callSign" | "flag";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    backgroundColor: alpha(theme.palette.common.white, 0.65),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.75),
    },
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: '1rem',
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },
    width: '100%'
}));

const DueToArriveSideBarContent: React.FC<DueToArriveSideBarContentProps> = ({
  logout,
  handleLogout,
  isLargeScreen,
  handleCloseSidebar,
}) => {
  useEffect(() => {
    async function initDueToArrive() {
      const data = await getVesselsDueToArrive();
      setVessels(data);
      setExtractedDates(extractDateRange(data))
    }
    initDueToArrive();
  }, []);

  const [searchBy, setSearchBy] = useState("vesselName");
  const [filteredDate, setFilteredDate] = useState("all");
  const [filterValue, setFilterValue] = useState("");
  const [extractedDates, setExtractedDates] = useState<string[]>([])
  const [vessels, setVessels] = useState<VesselDueToArrive[]>([]);
  function handleSearchByChange(searchByValue: string) {
    setSearchBy(searchByValue);
  }

  function handleFilteredDateChange(filteredDateValue: string) {
    setFilteredDate(filteredDateValue);
  }

  const filteredVessels = vessels.filter((vessel) =>
    vessel[searchBy as keyof VesselDueToArrive]
      .toString()
      .toLowerCase()
      .includes(filterValue.toLowerCase()) && (filteredDate === "all" || vessel.timestamp.toDateString() === filteredDate)
  );

  return (
    <>
      <div className="text-center p-4">
        <div
          className={`flex ${
            isLargeScreen ? "flex-row justify-between" : "justify-end"
          } mb-5 `}
        >
          {isLargeScreen && (
            <Close
              htmlColor="white"
              className="hover:cursor-pointer mr-4"
              onClick={() => handleCloseSidebar()}
            />
          )}
          <Button
            className="w-28 py-2 text-black font-bold"
            color="error"
            variant="contained"
            startIcon={<Logout />}
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Button>
        </div>
        <h1 className="text-white h-min inline-block whitespace-nowrap text-2xl xl:text-3xl">
          Due To Arrive
        </h1>
        <div className="flex flex-row text-white items-center my-5">
          <span className="text-left text-sm mr-2 w-24">Search By:</span>
          <StyledInputBase
            className='rounded-lg'
            // disableUnderline
            placeholder="Enter filter term"
            value={filterValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
            }}
            sx={{ fontSize: "14px" }}
            autoFocus
            endAdornment={
              <Select
                className="h-6 text-black"
                value={searchBy}
                variant='standard'
                disableUnderline
                sx={{
                  '.MuiSvgIcon-root ': {
                    fill: "black !important",
                  }
                }}
                onChange={(e) => handleSearchByChange(e.target.value)}
              >
                <MenuItem value={"vesselName"}>Name</MenuItem>
                <MenuItem value={"imoNumber"}>IMO</MenuItem>
                <MenuItem value={"callSign"}>Call Sign</MenuItem>
                <MenuItem value={"flag"}>Flag</MenuItem>
              </Select>
            }
          />
        </div>
        <div className="flex flex-row text-white items-baseline my-5 text-left">
          <span className="text-left text-sm mr-2 w-24">Date: </span>
          <Select
            variant="standard"
            disableUnderline
            className="h-7"
            value={filteredDate}
            label="Date"
            onChange={(e) => handleFilteredDateChange(e.target.value)}
          >
            <MenuItem value={"all"}>All Available Dates</MenuItem>
              {extractedDates.map(date => <MenuItem value={date}>{date}</MenuItem>)}
          </Select>
        </div>
        <div className="flex-grow overflow-hidden">
          <TableContainer
            style={{
              height: "calc(100vh - 250px)",
              maxHeight: "calc(100vh - 200px)",
              border: "1px solid rgba(224, 224, 224, 0.5)",
              overflowY: "auto",
            }}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
              scrollbarWidth: "thin",
              scrollbarColor: "#888 #f1f1f1",
            }}
          >
            <Table
              size="small"
              stickyHeader
              sx={{
                borderCollapse: "separate",
                borderSpacing: 0,
                "& .MuiTableCell-root": {
                  background: "rgba(0, 0, 0, 0.3)",
                  padding: "6px",
                  fontSize: "0.75rem",
                  borderRight: "1px solid rgba(224, 224, 224, 0.5)",
                  borderBottom: "1px solid rgba(224, 224, 224, 0.5)",
                  "&:last-child": {
                    borderRight: "none",
                  },
                },
                "& .MuiTableCell-head": {
                  // background: "white",
                  backgroundColor: "rgba(0, 0, 0)",
                  fontWeight: "bold",
                  zIndex: 1,
                  borderBottom: "2px solid rgba(224, 224, 224, 0.5)",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>IMO</TableCell>
                  <TableCell>Call Sign</TableCell>
                  <TableCell>Flag</TableCell>
                  <TableCell>Due To Arrive</TableCell>
                  {/* <TableCell>From</TableCell> */}
                  <TableCell>To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVessels.map((vessel, index) => (
                  <TableRow key={index}>
                    <TableCell>{vessel.vesselName}</TableCell>
                    <TableCell>{vessel.imoNumber}</TableCell>
                    <TableCell>{vessel.callSign}</TableCell>
                    <TableCell>{vessel.flag}</TableCell>
                    <TableCell>
                      {new Date(vessel.timestamp).toLocaleString([], {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                    </TableCell>
                    {/* <TableCell>{vessel.locationFrom}</TableCell> */}
                    <TableCell>{vessel.to}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default DueToArriveSideBarContent;
